<template>
  <div>
    <Pane />

    <a-card class="container">
      <a-row>
        <a-col :span="22">
          <a-form-model
            :colon="false"
            :model="form"
            layout="inline"
            @keyup.enter.native="query"
          >
            <a-form-model-item>
              <a-input
                v-model="form.number"
                placeholder="报告编号"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

                        <a-form-model-item>
              <a-input
                v-model="form.deptName"
                placeholder="报告单位"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

                        <a-form-model-item>
              <a-input
                v-model="form.accName"
                placeholder="事故名称"
                style="width: 150px"
              ></a-input>
            </a-form-model-item>

            <a-form-model-item>
              <a-space>
                <a-button @click="query" type="primary">查询</a-button>
                <a-button @click="reset">重置</a-button>
              </a-space>
            </a-form-model-item>
          </a-form-model>
        </a-col>
        <a-col :span="2">
          <div class="right" style="margin-top: 4px">
            <a-button 
                v-if="$getPermission($route.path + '/add')"
            @click="$router.push($route.path + '/add')" type="primary"
              >新增</a-button
            >
          </div>
        </a-col>
      </a-row>

      <Padding />

      <a-table
        bordered
        :data-source="list"
        :loading="loading"
        @change="onChange"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
        rowKey='id'
      >
        <a-table-column title="报告编号" data-index="number" align='center'> </a-table-column>
        <a-table-column title="报告单位" data-index="deptName" align='center'> </a-table-column>
        <a-table-column title="事故名称" data-index="accName"> </a-table-column>
        <a-table-column title="接收人" data-index="receiverName" align='center'> </a-table-column>
        <a-table-column title="报告人" data-index="reporterName" align='center'> </a-table-column>
        <a-table-column title="报告时间" data-index="reportTime" align='center'> </a-table-column>
        <a-table-column title="现场负责人" data-index="siteManagerName" align='center'> </a-table-column>

        <a-table-column title="操作" align="center" width="120px">
          <template slot-scope="text">
            <a-space>
                 <a
                href="#"
                @click.prevent="
                  $router.push($route.path + '/detail?id=' + text.id)
                "
                >详情</a
              >
              <a
                href="#"
                   v-if="$getPermission($route.path + '/edit')"
                @click.prevent="
                  $router.push($route.path + '/edit?id=' + text.id)
                "
                >编辑</a
              >
              <a href="#" class="danger" @click.prevent="deleteText(text)"
                >删除</a
              >
            </a-space>
          </template>
        </a-table-column>
      </a-table>
    </a-card>
  </div>
</template>


<script>
import watermark from "@/mixins/watermark";

import request from "@/api/request";

function fetchList (data) {
  return request({
      url: '/office-service/quality/acc/report/listPage',
    method: 'post',
    data,
  })
}

function remove(data) {
    return request({
      url: '/office-service/quality/acc/report/delete',
    method: 'post',
    data,
  })
}
export default {
  mixins: [watermark],

  data() {
    return {
      form: {},

      loading: false,
      current: 1,
      pageSize: 10,
      list: [],
      total: 0,
    };
  },

  mounted() {
    this.getList();
  },

  methods: {
    getList() {
      const { current, pageSize, form } = this;
      this.loading = true;
      fetchList({
        pageNum: current,
        pageSize: pageSize,
        ...form,
      })
        .then((res) => {
          if (Array.isArray(res.list)) {
            this.list = Object.freeze(res.list);
            this.total = res.totalSize;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },

    query() {
      this.current = 1;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },

    deleteText(text) {
      const that = this;
      this.$confirm({
        title: "确认要删除吗？",
        onOk() {
          remove({
            id: text.id,
          }).then(() => {
            that.getList();
          });
        },
      });
    },
  },
};
</script>